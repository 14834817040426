<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="invoice-preview-wrapper">
          <b-alert variant="danger" v-if="!arrival">
            <h4 class="alert-heading">Ошибка получения данных</h4>
            <div class="alert-body">
              Не найдено прихода с таким ID. Проверьте
              <router-link class="alert-link" :to="{ name: 'arrivals'}">Список приходов</router-link>
              чтобы увидеть другие приходы.
            </div>
          </b-alert>
          <div v-if="arrival && arrival.status" class="row invoice-preview">
            <div class="col-12">
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding pb-0">
                  <div class="d-flex justify-content-end flex-md-row flex-column invoice-spacing mt-0">
                    <div class="mt-md-0 mt-1">
                      <div class="row mb-3">
                        <div class="col-12 text-right">
                          <button class="btn btn-outline-secondary d-print-none" @click="printInvoice">
                            <span>Распечатать</span>
                          </button>
                          <router-link
                              :to="{ name: 'arrival-edit', params: { id: $route.params.id } }"
                              class="btn btn-primary ml-1"
                              v-if="$can('manage', 'arrivals') || arrival.status.id === 1"
                          >
                            Редактировать
                          </router-link>
                        </div>
                      </div>
                      <h4 class="invoice-title mb-1">
                        Приход <span class="invoice-number">#{{ $route.params.id }}</span>
                      </h4>
                      <div class="invoice-date-wrapper justify-content-end">
                        <p class="invoice-date-title">Дата создания прихода:</p>
                        <p class="invoice-date">{{ $formatters.formattedDate(arrival.created_at) }}</p>
                      </div>
                      <div class="invoice-date-wrapper justify-content-end">
                        <p class="invoice-date-title">Статус:</p>
                        <p class="invoice-date">{{ arrival.status.name }}</p>
                      </div>
                      <div class="invoice-date-wrapper justify-content-end" v-if="$can('manage', 'arrivals')">
                        <p class="invoice-date-title">Кто создал:</p>
                        <p class="invoice-date" v-if="arrival.creator">{{ arrival.creator.name }} {{ arrival.creator.surname }}</p>
                      </div>
                      <div class="invoice-date-wrapper justify-content-end" v-if="$can('manage', 'arrivals')">
                        <p class="invoice-date-title">Валюта прихода:</p>
                        <p class="invoice-date">{{ arrival.currency && arrival.currency.name }}</p>
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <hr class="invoice-spacing m-0" v-if="$can('read', 'users')">
                <b-card-body v-if="arrival.user && $can('read', 'users')" class="invoice-padding pt-0 mt-0">
                  <div class="row invoice-spacing">
                    <div class="col-12 col-xl-6 p-0">
                      <h6 class="mb-2">Поставщик:</h6>
                      <h6 class="mb-25">{{ arrival.user.name }} {{ arrival.user.surname }}</h6>
                      <p class="card-text mb-25">{{ arrival.user.company_name }}</p>
                      <p class="card-text mb-25">{{ arrival.user.phone }}</p>
                      <p class="card-text mb-0">{{ arrival.user.email }}</p>
                    </div>
                  </div>
                </b-card-body>
                <div class="table-responsive">
                  <table class="table b-table">
                    <thead>
                    <tr>
                      <th>Брак</th>
                      <th>Товар</th>
                      <th>Фото</th>
                      <th>Индекс хранения</th>
                      <th>Размер</th>
                      <th>Состояние</th>
                      <th>К-во</th>
                      <th>Вес (кг)</th>
                      <th v-if="$can('read', 'payments')">Сумма</th>
                      <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in arrival.items" :key="item.id">
                      <td>
                        <feather-icon
                            v-if="item.defected"
                            icon="CheckIcon"
                            size="16"
                            class="align-middle mr-50 text-danger"
                        />
                      </td>
                      <td>
                        <router-link
                            class="text-body"
                            :to="{name: 'product-details', params: { id: item.product_specification.product.id}}"
                        >
                          <b>{{ item.product_specification.product.name }} {{ item.product_specification.product.brand_name }}</b>
                        </router-link><br/>
                        <small>Артикул: {{ item.product_specification.product.sku }}</small> <br/>
                        <small v-if="item.product_specification.set_qty > 1">
                          Набор: {{ item.product_specification.set_qty }}шт
                        </small>
                      </td>
                      <td class="p-0">
                        <img
                            v-if="item.product_specification.product.small_main_image"
                            :src="`/img/product/${item.product_specification.product.small_main_image}`"
                            class="product-img" alt=""
                        >
                      </td>
                      <td>{{ item.storage_index }}</td>
                      <td class="text-uppercase">{{ item.product_specification.size_name }}</td>
                      <td>{{ item.product_specification.condition_name }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.product_specification.weight/1000 }}</td>
                      <td v-if="$can('read', 'payments')">
                        {{ item.cost }} <span class="currency">{{ item.currency.name }}</span>
                      </td>
                      <td>
                        <button class="btn btn-primary" @click="printBarcode(item.product_specification.id)">
                          Штрихкод
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <hr class="invoice-spacing">
                <b-card-body class="invoice-padding pb-0">
                  <div class="row">
                    <div class="col-12 order-1 order-md-2 mt-md-6 d-flex justify-content-end mb-2">
                      <div class="invoice-total-wrapper">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Кол-во:</p>
                          <p class="invoice-total-amount">{{ arrival.total_qty }}</p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">Вес (кг):</p>
                          <p class="invoice-total-amount">{{ arrival.total_weight/1000 }}</p>
                        </div>
                        <hr class="my-50" v-if="$can('read', 'payments')">
                        <div class="invoice-total-item" v-if="$can('read', 'payments')">
                          <p class="invoice-total-title">Сумма:</p>
                          <p class="invoice-total-amount">
                            {{ arrival.total_amount }} <span class="currency">{{ arrival.items[0].currency.name }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div id="print"></div>
  </div>

</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'ArrivalDetails',
  directives: {
    Ripple,
  },
  data() {
    return {
      arrival: {
        items: [],
        status: null,
      },
    };
  },
  components: { BCard, BCardBody },
  async mounted() {
    await this.getArrivalInfo();
  },
  methods: {
    async getArrivalInfo() {
      try {
        this.arrival = (await this.$api.arrivals.get(this.$route.params.id)).data;
      } catch (e) {
        this.arrival = undefined;
      }
    },
    printInvoice() {
      window.print();
    },
    async printBarcode(id) {
      const content = (await this.$api.productSpecifications.label(id)).data;
      const win = this.openWindow();

      win.document.write(content);
    },
    openWindow() {
      let windowRef = null;
      windowRef = window.open('/print', '_blank');
      if (!windowRef.opener) {
        windowRef.opener = this;
      }
      windowRef.focus();

      return windowRef;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
.invoice-date-title {
  width: auto !important;
}
.product-img {
  max-height: 80px;
}
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
span.currency {
  font-size: 10px;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #print, #print * {
    visibility:visible;
  }
  #print {
    position:absolute;
    left:0;
    top:0;
  }
}
</style>
